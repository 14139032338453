import React from "react"
import { graphql } from "gatsby"
import PageBuilder from "@/components/pageBuilder"
import ProjectCTA from "@/components/ProjectCTA"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Seo from "../components/seo"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
// import { Helmet } from "react-helmet"
// import Layout from "@/components/layout"

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProducts(id: { eq: $id }, _id: { glob: "!drafts*" }) {
      id
      _id
      ...PageBuilder
      title
      excerpt
      category {
        color
        title
      }
      mainImage {
        asset {
          url
        }
      }
      slug {
        current
      }
    }
  }
`

const ProductTemplate = props => {
  const { data } = props
  const product = data && data.product
  const { pageBuilder, _rawPageBuilder } = product
  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 block">
      <Seo
        title={product.title + " - Budmen"}
        description={product.excerpt}
        defer={false}
        image={urlFor(product.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
        twitterImage={urlFor(product.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
      />
      <div
        className=" text-[0.625rem] px-2 py-1 text-zinc-100"
        style={{ background: product.category.color }}
      >
        <AniLink
          className="text-zinc-200"
          to="/projects"
          paintDrip
          duration={0.5}
          hex={"#15b8a6"}
        >
          Projects{" "}
        </AniLink>
        / {product.category.title} / {product.title}
      </div>
      <div>
        <PageBuilder
          pageBuilder={pageBuilder}
          _rawPageBuilder={_rawPageBuilder}
        />
      </div>
      <ProjectCTA />
    </div>
  )
}

export default ProductTemplate
